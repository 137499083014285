import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./SingleTestimonialBlock.module.css";

const SingleTestimonialBlock = ({ block, styles }) => {

  const data = block.acf_testimonial;

  const image = {
    data: block.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.featuredImage?.node?.altText || ``,
  };

  return (
    <>
      {/* <div className={`${s.testItem}`}>
        <div className={`${s.borderedBack} d-flex flex-column align-items-center`}>
          <div className={`${s.testAvatar}`}>
            {!!image.data &&
              <GatsbyImage
                loading="eager"
                placeholder="dominantColor"
                image={image.data}
                alt={image.alt}
                width={150}
                height={150}
                className="mx-auto d-block w-100 h-100" />
            }
          </div>
          <h3 className={`${s.name} text-center`}>{block.title}</h3>
          {!!data.position &&
            <div className={`${s.position} d-block text-center`}>{data.position}</div>
          }
          {parse(block.content)}
          <div className={`${s.stars} stars`} data-percent={data.stars}></div><br />
        </div>
      </div> */}
      <div className={`h-100 ${s.testimonial}`} style={styles}>
        <div className={`row mx-0 h-100 flex-column position-relative flex-nowrap ${s.testimonialWrapper}`}>
          <div className={s.bottomCloud}>
            <div className={s.bottomCloudQuoteTop}>
              <svg width="85" height="67" viewBox="0 0 85 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.5987 33.3198H33.6676V66.6396H0V36.4846L17.7898 0H33.6676L21.5987 33.3198ZM72.4138 33.3198H84.4828V66.6396H50.8151V36.4846L68.6049 0H84.4828L72.4138 33.3198Z" fill="#4b6a88" />
              </svg>
            </div>
            <div className={s.bottomCloudQuoteBottom}>
              <svg width="85" height="67" viewBox="0 0 85 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M62.8857 33.3199L50.8167 33.3199L50.8167 8.86094e-05L84.4844 9.15527e-05L84.4844 30.1551L66.6946 66.6396L50.8167 66.6396L62.8857 33.3199ZM12.0706 33.3199L0.0015822 33.3199L0.00158511 8.4167e-05L33.6692 8.71103e-05L33.6692 30.1551L15.8794 66.6396L0.00157928 66.6396L12.0706 33.3199Z" fill="#4b6a88" />
              </svg>
            </div>
            <div className={s.bottomCloudEdge}></div>
          </div>
          <div className={`${s.testimonialMedia} `} >
            {!!image.data && <GatsbyImage
              placeholder="none"
              loading="eager"
              image={image.data}
              alt={image.alt}
              className="mw-100" />}
          </div>
          <div className="d-flex flex-column justify-content-between px-0 flex-grow-1">
            <div className={`${s.testimonialText} flex-grow-1`}>{parse(block?.content)}</div>
            <div className="d-flex flex-column">
              <h4 className={`${s.testimonialAuthor}`}>{block?.title}</h4>
              {!!data?.position &&
                <span className={`${s.testimonialPosition}`}>
                  {parse(data?.position)}
                </span>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleTestimonialBlock;
