import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./CaseStudiesGridBlock.module.css";
import CaseStudyGridItem from '../CaseStudyGridItem/CaseStudyGridItem';

const CaseStudiesGridBlock = ({ block }) => {
  const bp = useBreakpoint();

  const styles = {}
  if (!!block?.bgColor) {
    styles['--bg-color'] = block?.bgColor
  }
  if (!!block?.textColor) {
    styles['--text-color'] = block?.textColor
  }

  return (
    <>
      <section className={`${s.studiesGrid} position-relative`} id={block?.blockId} style={styles}>
        <div className={`container position-relative`}>
          {!!block?.title && <h2 className={`${s.secTitle} px-2 px-md-0`}>{parse(block.title)}</h2>}
          {!!block?.description && <div className={`${s.description} px-2 px-md-0`}>{parse(block.description)}</div>}
          {block?.items?.length > 0 && <div className={`${s.caseStudiesWrapper} row justify-content-around`}>
            {block?.items.map((item, i) => {
              return <div className='col-12 col-md-6 col-lg-5 col-xl-4 py-3' key={i}>
                <CaseStudyGridItem data={item.item} />
              </div>
            })}
          </div>}
          {!!block?.button?.url &&
            <div className='d-flex justify-content-center'>
              <Link
                to={block?.button?.url}
                target={block?.button?.target}
                className={`${s.button}`}
              >{parse(block?.button?.title)}</Link>
            </div>
          }
        </div>
      </section>
    </>
  );
};

export default CaseStudiesGridBlock;
