import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';
import parse from "html-react-parser";
import React from "react";
import * as s from "./IndustryExpertise.module.css";

const IndustryExpertise = ({ block }) => {
  const bp = useBreakpoint();
  const image = {
    data: block?.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block?.image?.altText || ``,
  };

  const styles = {}

  if (!!block?.bgGradient) {
    styles['--bg-gradient'] = block?.bgGradient;
  }

  return (
    <section className={`${s.block}`} id={block?.blockId} style={styles}>
      <div className="container">
        <div className={`row`}>
          <div className='col-lg-8'>
            {!!block.title && <h2 className={`${s.title}`}>{parse(block.title)}</h2>}
            {!!block.description && <div className={`${s.description}`}>{parse(block.description)}</div>}
          </div>
          <div className='col-12 mt-5 mb-4'></div>
          {(!!image?.data && !!bp?.lg) && <div className='col-lg-6 col-xxl-5'>
            <div className={s.leftImageWrapper}>
              <GatsbyImage
                loading="eager"
                placeholder="dominantColor"
                image={image.data}
                alt={image.alt}
                className={`mw-100`} />
            </div>
          </div>}
          <div className='col-lg-6 col-xxl-7'>
            <div className='h-100 d-flex flex-column justify-content-center'>
              {block.items.map((item, i) => {
                return <div key={`webinar-item-${i}`}>
                  <Item item={item} />
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Item = ({ item }) => {

  let icon = {
    src: item?.icon?.localFile?.publicURL,
    alt: item?.icon?.altText
  }

  return <div className={s.item}>
    {!!icon?.src && <img src={icon.src} alt={icon?.alt} loading='lazy' />}
    <div className='position-relative'>
      {!!item?.title && <div className={s.itemTitle}>{parse(item?.title)}</div>}
      {!!item?.description && <div className={s.itemDescription}>{parse(item?.description)}</div>}
    </div>
  </div>
}

export default IndustryExpertise;
