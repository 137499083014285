import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./CaseStudyGridItem.module.css";

const CaseStudyGridItem = ({ data }) => {

  const [tags, setTags] = React.useState([]);
  React.useEffect(() => {
    let tagsLocal = [];
    if (data.terms?.nodes?.length > 0) {
      tagsLocal = [
        ...tagsLocal,
        ...data.terms?.nodes?.map((t, i) => {
          return {
            title: t.name,
            url: t.uri,
            target: ''
          }
        })]
    }
    if (data?.acfCaseStudy?.mainData?.links?.length > 0) {
      tagsLocal = [
        ...tagsLocal,
        ...data?.acfCaseStudy?.mainData?.links?.map((item, i) => {
          return { ...item.link }
        })]
    }
    setTags(tagsLocal);
  }, [])

  const image = {
    data: data.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.featuredImage?.node?.altText || ``,
  };
  const logo = {
    data: data?.acfCaseStudy?.mainData?.logo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data?.acfCaseStudy?.mainData?.logo?.altText || ``,
  };
  return (
    <>
      <div className={`${s.studyGridItem} position-relative`} >
        <Link to={data.uri} className={`${s.imagesWrapper} d-block`}>
          {!!image?.data && <GatsbyImage
            className={`mw-100`}
            loading="eager"
            placeholder="transparent"
            image={image.data}
            alt={image.alt} />}
          <div className={s.logoWrapper}>
            {!!logo?.data && <GatsbyImage
              className={`mw-100`}
              loading="eager"
              placeholder="transparent"
              image={logo.data}
              alt={logo.alt} />}
          </div>
        </Link>
        <div className={s.tagsWrapper}>
          {tags.length > 0 && tags.map((t, i) => {
            return <Tag first={i == 0} tag={t} key={i} />
          })}
        </div>
      </div>
    </>
  );
};

const Tag = ({ tag, first }) => {
  return <>
    {!first && <span>|</span>}
    {/* <Link
      to={tag?.url}
      target={tag?.target}
    >{parse(tag?.title)}</Link> */}
    <span>{parse(tag?.title)}</span>
  </>
}

export default CaseStudyGridItem;
