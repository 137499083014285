

import { StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useState } from "react";

import { isEmailValid, isUrlValid } from '../../../utils/helper';
import makeZapierRequest from "../../../utils/zapierRequests";
import zapiers from '../../../utils/zapiers';
import * as s from "./IndustryFormBlock.module.css";

import FieldDropdown from "../../FormsComponents/fields/fieldDropdown";
import FieldText from "../../FormsComponents/fields/fieldText";

const IndustryFormBlock = ({ block, page }) => {
  const [messageSent, setMessageSent] = useState(false);

  const handleFormSubmit = (payload) => {
    makeZapierRequest({ ...payload, page: page });
    setMessageSent(true);
  }

  return (
    <>
      <section className={`${s.block} d-flex`} id={block?.blockId}>
        <StaticImage
          src={'./Letter.png'}
          className={`${s.leftBg} d-none d-lg-block`}
          alt=""
          quality={100}
        />
        <div className="container position-relative">
          <div className={`${s.row} row justify-content-center`}>
            <div className='col-xxl-11 offset-xxl-1'>

              <div className="row align-items-center">
                <div className="col-lg-5">
                  <h2 className={`${s.title} px-3 px-lg-0`}>Ready<br /> to get started?</h2>
                </div>
                <div className="col-lg-7">
                  <div className={`${s.formWrapper} px-3 px-lg-0`}>
                    <StaticImage
                      src={'./Form-bg.png'}
                      className={`${s.formBg}`}
                      alt=""
                      quality={100}
                    />
                    <div className={s.formTitle}>Reach out to us by filling out the form below.</div>
                    <Form block={block?.form} onSubmit={handleFormSubmit} />
                    {!!messageSent && <div className={s.thankYouText}>Thanks for your interest!</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};


const Form = ({ block, onSubmit }) => {

  const defaultFormData = {
    firstName: '',
    lastName: '',
    email: '',
    companyUrl: '',
    position: '',
    revenue: '',
    plannedMonthlyBudget: '',

  }
  const defaultErrorData = {
    firstName: false,
    lastName: false,
    email: false,
    position: false,
    companyUrl: false,
    revenue: false,
    plannedMonthlyBudget: false,
  }
  const [formData, setFormData] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState(defaultErrorData);

  const handleInputChange = (name, value) => {
    let newFormData = { ...formData };
    newFormData[name] = value;
    setFormData(newFormData);
  }

  const submitForm = () => {
    if (isValidForm()) {
      // setMessageSent(true);
      let payload = {
        event: 'formSubmit',
        type: zapiers.industryForm.type,
        ...formData
      };

      onSubmit(payload)
      cleanForm();
    }
  }

  const isValidForm = () => {
    let isValid = true;
    let newErrors = {};

    if (!formData?.firstName?.length) {
      isValid = false;
      newErrors.firstName = 'Please enter a First Name';
    } else {
      newErrors.firstName = false;
    }

    if (!formData?.lastName?.length) {
      isValid = false;
      newErrors.lastName = 'Please enter a Last Name';
    } else {
      newErrors.lastName = false;
    }

    if (!isEmailValid(formData?.email)) {
      isValid = false;
      newErrors.email = 'Please enter a valid Email';
    } else {
      newErrors.email = false;
    }

    if (!isUrlValid(formData?.companyUrl)) {
      isValid = false;
      newErrors.companyUrl = 'Please enter a valid URL';
    } else {
      newErrors.companyUrl = false;
    }

    if (!formData?.position?.length) {
      isValid = false;
      newErrors.position = 'Required Field';
    } else {
      newErrors.position = false;
    }

    if (!formData?.revenue?.length) {
      isValid = false;
      newErrors.revenue = 'Required Field';
    } else {
      newErrors.revenue = false;
    }

    if (!formData?.plannedMonthlyBudget?.length) {
      isValid = false;
      newErrors.plannedMonthlyBudget = 'Required Field';
    } else {
      newErrors.plannedMonthlyBudget = false;
    }

    setFormErrors(newErrors);
    // isValid = false;
    return isValid;
  }

  const cleanForm = () => {
    setFormData(defaultFormData);
    setFormErrors(defaultErrorData);
  }

  return <>

    <div className="row">
      <div className="col-12 col-lg-5 pe-lg-1">
        <FieldText
          name={'firstName'}
          value={formData['firstName']}
          label={'First Name'}
          placeholder={'Enter your first name'}
          required={true}
          inputStyles={{ fontWeight: '400' }}
          dark={true}
          invalid={formErrors['firstName']}
          onchange={(value) => handleInputChange('firstName', value)} />
      </div>
      <div className="col-12 col-lg-7 ps-lg-1">
        <FieldText
          name={'lastName'}
          value={formData['lastName']}
          label={'Last Name'}
          placeholder={'Enter your last name'}
          required={true}
          inputStyles={{ fontWeight: '400' }}
          dark={true}
          invalid={formErrors['lastName']}
          onchange={(value) => handleInputChange('lastName', value)} />
      </div>
      <div className='col-12'>
        <FieldText
          name={'email'}
          value={formData['email']}
          label={'Your Email'}
          placeholder={'Enter your email'}
          required={true}
          inputStyles={{ fontWeight: '400' }}
          dark={true}
          invalid={formErrors['email']}
          onchange={(value) => handleInputChange('email', value)} />
      </div>
      <div className="col-12 col-lg-5 pe-lg-1">
        <FieldText
          name={'companyUrl'}
          value={formData['companyUrl']}
          label={'Company URL'}
          placeholder={'Your Company URL'}
          required={true}
          inputStyles={{ fontWeight: '400' }}
          dark={true}
          invalid={formErrors['companyUrl']}
          onchange={(value) => handleInputChange('companyUrl', value)} />
      </div>
      <div className="col-12 col-lg-7 ps-lg-1">
        <FieldText
          name={'position'}
          value={formData['position']}
          label={'Current Job Title'}
          placeholder={'CEO & Founder'}
          required={true}
          inputStyles={{ fontWeight: '400' }}
          dark={true}
          invalid={formErrors['position']}
          onchange={(value) => handleInputChange('position', value)} />
      </div>
      <div className="col-12 col-lg-6 pe-lg-1">
        <FieldDropdown
          styles={{}}
          name={'revenue'}
          value={formData['revenue']}
          label={`Last Year's Revenue`}
          bigLabel={false}
          placeholder={'Revenue Options (Please select one)'}
          required={true}
          inputStyles={{ fontWeight: '400', fontSize: '14.8px' }}
          dark={true}
          options={[
            { option: 'Less than $1,000,000' },
            { option: '$1,000,000 to $5,000,000' },
            { option: '$5,000,000 to $15,000,000' },
            { option: 'More than $15,000,000' },
          ]}
          invalid={formErrors['revenue']}
          onchange={(value) => handleInputChange('revenue', value)} />
      </div>
      <div className="col-12 col-lg-6 ps-lg-1">
        <FieldDropdown
          styles={{}}
          name={'plannedMonthlyBudget'}
          value={formData['plannedMonthlyBudget']}
          label={`Your Monthly Marketing Budget`}
          bigLabel={false}
          placeholder={'Select Your Monthly Marketing Budget'}
          required={true}
          inputStyles={{ fontWeight: '400', fontSize: '14.8px' }}
          dark={true}
          options={[
            { option: '$0-$5.000 | Monthly' },
            { option: '$5.000 - $10.000 | Monthly' },
            { option: '$10.000-$25.000 | Monthly' },
            { option: 'More than $25,000 | Monthly' },
          ]}
          invalid={formErrors['plannedMonthlyBudget']}
          onchange={(value) => handleInputChange('plannedMonthlyBudget', value)} />
      </div>
      <div className="col-12 mt-4">
        <button type='button' className={`${s.submitButton} btn-main`} onClick={submitForm}>{parse(block?.submitButtonText ?? 'Submit')}</button>
      </div>
    </div>
  </>
}

export default IndustryFormBlock;
