import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import Slider from "react-slick";
import * as s from "./HomeTestimonialsBlock.module.css";
import SingleTestimonialBlock from "./SingleTestimonialBlock";

const HomeTestimonialsBlock2 = ({ block, display }) => {

  const reviewsRef = useRef();
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    reviewsRef,
    {},
    { disconnectOnLeave: false },
    {}
  );


  const sliderSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 3,
    infinite: true,
    centerMode: true,
    swipeToSlide: true,
    // autoplay: true,
    autoplaySpeed: 6000,
    variableWidth: false,
    lazyLoad: true,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1199.9,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767.9,
        settings: {
          slidesToShow: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  if (undefined === display) {
    display = true;
  }

  const styles = {};
  if (!!block?.bgColor) {
    styles['--bg-color'] = block?.bgColor;
  }
  if (!!block?.textColor) {
    styles['--title-color'] = block?.textColor;
  }

  return (
    <>
      {display &&
        <section className={`${s.testimonials}`} ref={reviewsRef} id={block?.blockId} style={styles}>
          <div className="container">
            <h2 className={`${s.secTitle} text-center`}>{block.title}</h2>
          </div>
          <div className={`${s.testimonialSlider} testimonial-slider`}>
            {(enterCount > 0 && !!block.testimonials) && <>
              {(block.testimonials.length > 2) ?
                <Slider {...sliderSettings} >
                  {block.testimonials.map((item, i) =>
                    <SingleTestimonialBlock block={item.testimonial} key={`testimonial-slider-item-${i}`} styles={styles} />
                  )
                  }
                </Slider>
                :
                <div className='row justify-content-center'>
                  {block.testimonials.map((item, i) =>
                    <div className='col-lg-6 col-xxl-4' key={`testimonial-${i}`}>
                      <SingleTestimonialBlock block={item.testimonial} styles={styles} />
                    </div>
                  )}
                </div>
              }
            </>
            }
          </div>
        </section>
      }
    </>
  );
};

export default HomeTestimonialsBlock2;
