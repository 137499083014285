// extracted by mini-css-extract-plugin
export var accordionItem = "FaqRedesigned-module--accordion-item--a9b55";
export var accordionWrapper = "FaqRedesigned-module--accordion-wrapper--a2ab0";
export var answer = "FaqRedesigned-module--answer--b9475";
export var ctaBtn = "FaqRedesigned-module--ctaBtn--269f4";
export var faq = "FaqRedesigned-module--faq--0aed4";
export var imageWrapper = "FaqRedesigned-module--imageWrapper--0226d";
export var marker = "FaqRedesigned-module--marker--f4816";
export var question = "FaqRedesigned-module--question--1ba20";
export var sectionImage = "FaqRedesigned-module--section-image--8f9c8";
export var sectionTitle = "FaqRedesigned-module--section-title--0ff7d";
export var togglerIcon = "FaqRedesigned-module--toggler-icon--67425";
export var wrapper = "FaqRedesigned-module--wrapper--ffcf0";