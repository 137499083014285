import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./IndustryHero.module.css";

const IndustryHero = ({ block }) => {
  const bp = useBreakpoint();

  const backgroundImage = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };

  const backgroundImageMobile = {
    data: block.imageMobile?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.imageMobile?.altText || ``,
  };



  return (
    <>
      <section className={`${s.topBlock} position-relative`} id={block?.blockId}>
        <div className={`${s.content}`}>
          <div className={`container position-relative`}>
            <div className='row justify-content-center'>
              <div className='col-lg-10 col-xl-9 col-xxl-8'>
                <div className={s.contentWrapper}>
                  <h1 className={`${s.secTitle} px-2 px-md-0`}>{parse(block.title)}</h1>
                  {!!block.description && <div className={`${s.description} px-2 px-md-0`}>{parse(block.description)}</div>}
                  {!!block?.link?.url && <div className='d-flex justify-content-center mt-3 mt-md-4 pt-md-3'><Link to={block?.link?.url} target={block?.link?.url} className='btn-main'>{block?.link?.title}</Link></div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        {bp.md == true && <>
          {!!backgroundImage?.data && <GatsbyImage
            className={s.bg}
            loading="eager"
            placeholder="transparent"
            image={backgroundImage.data}
            alt={backgroundImage.alt} />}
        </>
        }
        {bp.md == false && <>
          {!!backgroundImageMobile?.data && <GatsbyImage
            className={s.bg}
            loading="eager"
            placeholder="transparent"
            image={backgroundImageMobile.data}
            alt={backgroundImageMobile.alt} />}
        </>
        }
      </section>
    </>
  );
};

export default IndustryHero;
