import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import parse from "html-react-parser";
import React, { useState, useRef } from "react";
import { useInViewport } from 'react-in-viewport';

import * as s from './FaqRedesigned.module.css';
import QuestionImage from './Question.svg';


const FAQRedesigned = ({ block }) => {
  const bp = useBreakpoint();

  const blockRef = useRef();
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    blockRef,
    {},
    { disconnectOnLeave: false },
    {}
  );

  return (
    <>
      <section className={s.faq} ref={blockRef}>
        <div className={s.wrapper}>
          {enterCount > 0 &&
            <div className="container">
              {!bp.lg && <div className="d-flex justify-content-between align-items-center">
                {!!block.title && <h2 className={s.sectionTitle}>{block.title}</h2>}
                <div className={s.sectionImage}>
                  <div className={s.imageWrapper}>
                    <img src={QuestionImage} className={`mw-100`} loading='lazy' />
                  </div>
                </div>
              </div>
              }
              {bp.lg && !!block.title && <h2 className={s.sectionTitle}>{block.title}</h2>}
              <div className="row">
                <div className="col-lg-7">
                  <div className={s.accordionWrapper}>
                    <Accordion data={block.items} />
                  </div>
                </div>
                {bp.lg && <div className="col-lg-5">
                  <div className={s.imageWrapper} >
                    <img src={QuestionImage} className={`mw-100`} loading='lazy' />
                  </div>
                </div>}
              </div>
            </div >
          }
        </div >
      </section >
    </>
  )
}
export default FAQRedesigned

const Accordion = ({ data }) => {
  const [openItemIndex, setOpenItemIndex] = useState(null);

  const toggleItem = (index) => {
    setOpenItemIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      {data.map((item, index) => (
        <AccordionItem
          key={index}
          question={item.question}
          answer={item.answer}
          isOpen={openItemIndex === index}
          onClick={() => toggleItem(index)}
        />
      ))}
    </div>
  );
};

const AccordionItem = ({ question, answer, isOpen, onClick }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick();
    }
  };
  return (
    <div className={s.accordionItem}>
      <div
        role="button"
        tabIndex="0" // Make the item focusable
        onClick={onClick}
        onKeyDown={handleKeyDown} >
        <div className={s.question}>
          <span className={s.togglerIcon}>
            {isOpen ? '-' : '+'}
          </span>
          <span className={s.marker}>Q:</span>
          <h3>
            {parse(question)}
          </h3>
        </div>
      </div>
      {isOpen && <div className={`${s.answer} d-flex`}><span className={s.marker}>A:</span><div>{parse(answer)}</div></div>}
    </div>
  );
};
