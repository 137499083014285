import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import parse from "html-react-parser";

import * as s from "./fields.module.css";

const FieldText = ({ name, value, label, placeholder, required, invalid, onchange, dark, inputStyles }) => {

    if (undefined == inputStyles) {
        inputStyles = {}
    }

    return <>
        <div className={`${s.labelInputGroup} ${true == dark ? s.dark : ''} w-100 position-relative`}>
            <label>{parse(label)} {!!required && <>*</>}</label>
            <input
                type="text"
                name={name}
                value={value}
                onChange={e => onchange(e.target.value)}
                placeholder={placeholder}
                style={inputStyles}/>
            {!!invalid && <small className={`invalid-feedback position-absolute d-block`}
                style={{
                    bottom: 0,
                    color: 'var(--invalid-message-color)',
                }}
            >{invalid}</small>}
        </div>
    </>
}

export default FieldText