import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./IndustrySolution.module.css";

const IndustrySolutionBlock = ({ block }) => {
  const bp = useBreakpoint();

  return (
    <>
      <section className={`${s.block} position-relative`} id={block?.blockId} >
        <div className={`container position-relative`}>
          <div className='row'>
            <div className='col-12'>
              {!!block?.title && <h2 className={s.title}>{parse(block?.title)}</h2>}
            </div>
          </div>
          <div className='row justify-content-center mt-3 mt-md-5' style={{ rowGap: '25px' }}>
            {block?.actions?.length > 0 && <>
              {block?.actions?.map((a, i) => {
                return <div key={i} className='col-xxl-4 col-md-6'>
                  <ActionItem action={a} index={i + 1} />
                </div>
              })}
            </>}
          </div>
        </div>
      </section>
    </>
  );
};

const ActionItem = ({ action, index }) => {

  return <div className={s.actionItem}>
    <div className={s.number}>{index}</div>
    <div className={s.actionContent}>
      {!!action.title && <div className={s.actionTitle}>{parse(action.title)}</div>}
      {!!action.description && <div className={s.actionDescription}>{parse(action.description)}</div>}
    </div>
  </div>
};

export default IndustrySolutionBlock;
