// extracted by mini-css-extract-plugin
export var bottomCloud = "SingleTestimonialBlock-module--bottom-cloud--9f45f";
export var bottomCloudEdge = "SingleTestimonialBlock-module--bottom-cloud-edge--1bcdf";
export var bottomCloudQuoteBottom = "SingleTestimonialBlock-module--bottom-cloud-quote-bottom--8ebf4";
export var bottomCloudQuoteTop = "SingleTestimonialBlock-module--bottom-cloud-quote-top--292f2";
export var testimonial = "SingleTestimonialBlock-module--testimonial--33609";
export var testimonialAuthor = "SingleTestimonialBlock-module--testimonialAuthor--7f696";
export var testimonialBg = "SingleTestimonialBlock-module--testimonial-bg--ace86";
export var testimonialMedia = "SingleTestimonialBlock-module--testimonial-media--e7550";
export var testimonialPosition = "SingleTestimonialBlock-module--testimonialPosition--d1fe0";
export var testimonialText = "SingleTestimonialBlock-module--testimonial-text--a57b5";
export var testimonialWrapper = "SingleTestimonialBlock-module--testimonial-wrapper--40e6d";