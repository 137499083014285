import { graphql } from "gatsby";
import parse from "html-react-parser";
import React, { useEffect } from "react";

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../assets/css/@wordpress/block-library/build-style/style.css";
import "../assets/css/@wordpress/block-library/build-style/theme.css";

import FAQRedesigned from './../components/FaqRedesigned/FaqRedesigned';
import HomeTestimonialsBlock2 from './../components/HomeTestimonialsBlock2/HomeTestimonialsBlock2';
import CaseStudiesGridBlock from './../components/caseStudiesMain/CaseStudiesGridBlock/CaseStudiesGridBlock';
import Footer from "./../components/footer/footer";
import Header from "./../components/header/header";
import IndustryExpertise from './../components/industry/IndustryExpertise/IndustryExpertise';
import IndustryFormBlock from './../components/industry/IndustryFormBlock/IndustryFormBlock';
import IndustryHero from './../components/industry/IndustryHero/IndustryHero';
import IndustryProblemBlock from "./../components/industry/IndustryProblemBlock/IndustryProblemBlock";
import IndustrySolutionBlock from "./../components/industry/IndustrySolution/IndustrySolution";
import IndustryWhoWeHelp from './../components/industry/IndustryWhoWeHelp/IndustryWhoWeHelp';
import Seo from "./../components/seo";
import schemaBuilder from "./../utils/schemaBuilder";

const IndustryTemplate = ({ data: { page, author, site }, location }) => {

  const [display, setDisplay] = React.useState(false);
  useEffect(() => {
    setTimeout(function () {
      setDisplay(true);
    }, 200);
  }, []);

  // const vacancyRef = React.useRef(null);
  const [vacancyRef, setVacancyRef] = React.useState(null);
  const [quizRef, setQuizRef] = React.useState(null);
  // scroll to ref example function
  const scrollToRef = (ref) => {
    ref?.scrollIntoView({ behavior: "smooth" });
  };

  const schema = schemaBuilder({ page: page, author: author }, 'page', site);

  return (
    <div>
      <Header customBanner={{...page?.headerBannerLocal?.headerBannerLocal, id: page.id}} />
      <main>
        <Seo
          title={page.title}
          description={""}
          pageSeo={page}
          location={location}
          schema={schema}
        />
        {
          !!page?.acf_industry?.industryContent &&
          page?.acf_industry?.industryContent.map((block, index) => {
            switch (block.__typename) {
              case 'WpIndustry_AcfIndustry_IndustryContent_Hero':
                return <IndustryHero block={block} key={`block-${index}`} />
                break;
              case 'WpIndustry_AcfIndustry_IndustryContent_ProblemBlock':
                return <IndustryProblemBlock block={block} key={`block-${index}`} />
                break;
              case 'WpIndustry_AcfIndustry_IndustryContent_SolutionBlock':
                return <IndustrySolutionBlock block={block} key={`block-${index}`} />
                break;
              case 'WpIndustry_AcfIndustry_IndustryContent_ExpertiseBlock':
                return <IndustryExpertise block={block} key={`block-${index}`} />
                break;
              case 'WpIndustry_AcfIndustry_IndustryContent_WhoWeHelpBlock':
                return <IndustryWhoWeHelp block={block} key={`block-${index}`} />
                break;
              case 'WpIndustry_AcfIndustry_IndustryContent_TestimonialsBlock':
                return <HomeTestimonialsBlock2 block={block} key={`block-${index}`} />
                break;
              case 'WpIndustry_AcfIndustry_IndustryContent_CaseStudiosBlock':
                return <CaseStudiesGridBlock block={block} key={`block-${index}`} />
                break;
              case 'WpIndustry_AcfIndustry_IndustryContent_FormBlock':
                return <IndustryFormBlock block={block} key={`block-${index}`} page={`Industry Page - ${page.title}`} />
                break;
              case 'WpIndustry_AcfIndustry_IndustryContent_Faq':
                return <FAQRedesigned block={block} key={`block-${index}`} />
                break;

              default:
                return <section className="container d-none" style={{ 'wordBreak': 'break-word' }} key={`block-${index}`}>
                  {block.__typename}
                  {/* {JSON.stringify(block, null ,2)} */}
                  <hr />
                </section>
            }
          })
        }
        <section className="container">
          {!!page.content && (
            <section>{parse(page.content)}</section>
          )}
        </section>
      </main>
      <Footer hideSubscribeBlock={true} pageTitle={`Industry Page - ${page.title}`} />
    </div>
  )
}

export default IndustryTemplate

export const pageQuery = graphql`
  query IndustryById(
    $id: String!
    $authorId: String!
  ) {
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
    page: wpIndustry(id: {eq: $id }) {
      id
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
      headerBannerLocal {
        headerBannerLocal {
          hideBannerOnThisPage
          useLocal
          title
          description
          link {
            target
            title
            url
          }
          backgroundGradient
        }
      }
      acf_industry {
        industryContent {
          ... on WpIndustry_AcfIndustry_IndustryContent_Hero {
            __typename
            blockId
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            imageMobile {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            title
            description
            link {
              title
              url
              target
            }
          }
          ... on WpIndustry_AcfIndustry_IndustryContent_ProblemBlock {
            __typename
            blockId
            bgColor
            textColor
            title
            problem
            solution
            showArrow
          }
          ... on WpIndustry_AcfIndustry_IndustryContent_SolutionBlock {
            __typename
            blockId
            title
            actions {
              title
              description
            }
          }
          ... on WpIndustry_AcfIndustry_IndustryContent_ExpertiseBlock {
            __typename
            blockId
            bgGradient
            title
            description
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            items {
              icon {
                altText
                localFile {
                  publicURL
                }
              }
              title
              description
            }
          }
          ... on WpIndustry_AcfIndustry_IndustryContent_WhoWeHelpBlock {
            __typename
            blockId
            bgImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            title
            items {
              icon {
                altText
                localFile {
                  publicURL
                }
              }
              title
              subIndustryPage {
                ... on WpIndustry {
                  uri
                  title
                }
              }
              description
            }
          }
          ... on WpIndustry_AcfIndustry_IndustryContent_TestimonialsBlock {
            __typename
            blockId
            bgColor
            textColor
            title
            testimonials {
              testimonial {
                __typename
                ... on WpTestimonial {
                  acf_testimonial {
                    position
                    stars
                  }
                  content
                  title
                  featuredImage {
                    node {
                      __typename
                      ... on WpMediaItem {
                        mediaItemUrl
                        localFile {
                          childImageSharp {
                            gatsbyImageData(quality: 100)
                          }
                        }
                        altText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpIndustry_AcfIndustry_IndustryContent_CaseStudiosBlock {
            __typename
            blockId
            bgColor
            textColor
            title
            description
            items {
              item {
                ... on WpCaseStudy {
                  id
                  title
                  uri
                  featuredImage {
                    node {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100)
                        }
                      }
                    }
                  }
                  acfCaseStudy {
                    mainData {
                      logo {
                        altText
                        localFile {
                          childImageSharp {
                            gatsbyImageData(quality: 100)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            button {
              target
              title
              url
            }
          }
          ... on WpIndustry_AcfIndustry_IndustryContent_FormBlock {
            __typename
            blockId
          }
          ... on WpIndustry_AcfIndustry_IndustryContent_Faq {
            __typename
            blockId
            title
            items {
              question
              answer
            }
          }
        }
      }
    }
    author: wpUser(id: {eq: $authorId}) {
      avatar {
        size
        url
      }
      users {
        avatar {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
      name
      description
      email
      slug
      uri
      seo {
        metaDesc
        social {
          facebook
          linkedIn
          twitter
          wikipedia
        }
      }
    }
  }
`