import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import parse from "html-react-parser";

import * as s from "./fields.module.css";

const FieldDropdown = ({ name, value, label, bigLabel, placeholder, required, invalid, options, onchange, dark, styles, inputStyles }) => {

    const [opened, setOpened] = useState(false)

    const setValue = (value) => {
        onchange(value);
        setOpened(false);
    }

    if (undefined == inputStyles) {
        inputStyles = {}
    }

    let style = {}

    if (!!styles?.inputGroup) {
        style = { ...style, ...styles?.inputGroup }
    }

    let dropdownStyles = {};
    if (!!styles?.dropdown) {
        dropdownStyles = { ...dropdownStyles, ...styles?.dropdown }
    }

    let invalidStyles = {};
    if (!!styles?.invalidMessage) {
        invalidStyles = { ...invalidStyles, ...styles?.invalidMessage }
    }
    return <>
        {opened && <div className={s.bg} onClick={() => { setOpened(false) }}></div>}
        <div className={`${s.labelInputGroup} ${true == dark ? s.dark : ''} ${opened ? s.opened : ''} w-100 position-relative`} style={style}>
            <label className={`${true == bigLabel ? s.big : ''}`}>{parse(label)} {!!required && <>*</>}</label>
            <div className={`${s.dropdownWrapper} ${opened ? s.opened : ''}`} style={dropdownStyles}>
                <div className="position-relative" style={{ zIndex: '2' }} onClick={() => { setOpened(!opened) }}>
                    <div className={`${s.valueHolder} ${!!value ? s.selected : ''} d-flex justify-content-between align-items-center`} style={inputStyles}>
                        <span>{!!value ? value : placeholder}</span>
                        {opened ? <ArrowUp /> : <ArrowDown />}
                    </div>
                </div>
                {opened && <div className={`${s.optionsList} d-flex flex-column`}>
                    {options.map((o, i) => {
                        return <div className={s.option} key={i} onClick={() => { setValue(o.option) }}>{o.option}</div>
                    })}
                </div>}
            </div>
            {!!invalid && <small className={`invalid-feedback position-absolute d-block`}
                style={{
                    ...invalidStyles,
                    bottom: 0,
                    color: 'var(--invalid-message-color)',
                }}
            >{invalid}</small>}
        </div>
    </>
}

export default FieldDropdown

const ArrowDown = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.01932 19.9539C2.88845 19.9539 2.02752 19.6657 1.43655 19.0893C0.852874 18.513 0.561035 17.6593 0.561035 16.5285V3.95386C0.561035 2.82298 0.852874 1.96935 1.43655 1.39297C2.02752 0.809295 2.88845 0.517456 4.01932 0.517456H16.5501C17.6883 0.517456 18.5492 0.809295 19.1329 1.39297C19.7166 1.97665 20.0084 2.83028 20.0084 3.95386V16.5285C20.0084 17.652 19.7166 18.502 19.1329 19.0784C18.5492 19.6621 17.6883 19.9539 16.5501 19.9539H4.01932ZM4.18348 17.7761H16.386C16.8456 17.7761 17.1995 17.6557 17.4476 17.4149C17.6956 17.1741 17.8196 16.8093 17.8196 16.3205V4.16179C17.8196 3.67296 17.6956 3.30816 17.4476 3.0674C17.1995 2.82663 16.8456 2.70625 16.386 2.70625H4.18348C3.72384 2.70625 3.36998 2.82663 3.12192 3.0674C2.88115 3.30816 2.76077 3.67296 2.76077 4.16179V16.3205C2.76077 16.8093 2.88115 17.1741 3.12192 17.4149C3.36998 17.6557 3.72384 17.7761 4.18348 17.7761ZM10.3012 4.90598C10.5784 4.90598 10.8082 4.99353 10.9906 5.16864C11.1803 5.34374 11.2752 5.56991 11.2752 5.84716V10.6078L11.1767 12.72L12.085 11.5708L13.2013 10.3889C13.3764 10.2065 13.5989 10.1153 13.8689 10.1153C14.1242 10.1153 14.3358 10.1956 14.5036 10.3561C14.6714 10.5166 14.7553 10.7209 14.7553 10.9689C14.7553 11.2243 14.6678 11.4468 14.4927 11.6365L11.0891 15.0948C10.8556 15.3356 10.593 15.4559 10.3012 15.4559C10.0166 15.4559 9.7576 15.3356 9.52413 15.0948L6.12056 11.6365C5.94546 11.4541 5.85791 11.2316 5.85791 10.9689C5.85791 10.7209 5.94181 10.5166 6.10962 10.3561C6.27742 10.1956 6.48536 10.1153 6.73342 10.1153C7.00337 10.1153 7.2259 10.2065 7.401 10.3889L8.52823 11.5818L9.42564 12.709L9.32714 10.6078V5.84716C9.32714 5.56991 9.41834 5.34374 9.60074 5.16864C9.79043 4.99353 10.0239 4.90598 10.3012 4.90598Z" fill="#A7A7A7" />
    </svg>
}

const ArrowUp = () => {
    return <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.1182 0.549871C2.98733 0.549871 2.1264 0.838062 1.53543 1.41444C0.951751 1.99083 0.659912 2.84445 0.659912 3.97533V16.5499C0.659912 17.6808 0.951751 18.5344 1.53543 19.1108C2.1264 19.6945 2.98733 19.9863 4.1182 19.9863H16.649C17.7872 19.9863 18.6481 19.6945 19.2318 19.1108C19.8155 18.5271 20.1073 17.6735 20.1073 16.5499V3.97533C20.1073 2.85175 19.8155 2.00177 19.2318 1.42539C18.6481 0.841709 17.7872 0.549871 16.649 0.549871H4.1182ZM4.28236 2.72772H16.4849C16.9445 2.72772 17.2984 2.8481 17.5464 3.08887C17.7945 3.32964 17.9185 3.69444 17.9185 4.18327V16.342C17.9185 16.8308 17.7945 17.1956 17.5464 17.4364C17.2984 17.6772 16.9445 17.7975 16.4849 17.7975H4.28236C3.82271 17.7975 3.46886 17.6772 3.2208 17.4364C2.98003 17.1956 2.85965 16.8308 2.85965 16.342V4.18327C2.85965 3.69444 2.98003 3.32964 3.2208 3.08887C3.46886 2.8481 3.82271 2.72772 4.28236 2.72772ZM10.4 15.5978C10.6773 15.5978 10.9071 15.5103 11.0895 15.3351C11.2792 15.16 11.374 14.9339 11.374 14.6566V9.89601L11.2755 7.78382L12.1839 8.93294L13.3002 10.1149C13.4753 10.2973 13.6978 10.3885 13.9678 10.3885C14.2231 10.3885 14.4347 10.3082 14.6025 10.1477C14.7703 9.98721 14.8542 9.78292 14.8542 9.53486C14.8542 9.2795 14.7667 9.05697 14.5916 8.86728L11.188 5.40899C10.9545 5.16822 10.6919 5.04784 10.4 5.04784C10.1155 5.04784 9.85648 5.16822 9.62301 5.40899L6.21944 8.86728C6.04434 9.04967 5.95678 9.2722 5.95678 9.53486C5.95678 9.78292 6.04069 9.98721 6.20849 10.1477C6.3763 10.3082 6.58424 10.3885 6.8323 10.3885C7.10225 10.3885 7.32478 10.2973 7.49988 10.1149L8.62711 8.922L9.52451 7.79477L9.42602 9.89601V14.6566C9.42602 14.9339 9.51722 15.16 9.69961 15.3351C9.88931 15.5103 10.1228 15.5978 10.4 15.5978Z" fill="#A7A7A7" />
    </svg>
}