import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';
import parse from "html-react-parser";
import React from "react";
import * as s from "./IndustryWhoWeHelp.module.css";

const IndustryWhoWeHelp = ({ block }) => {
  const bp = useBreakpoint();
  const bgImage = {
    data: block?.bgImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block?.bgImage?.altText || ``,
  };

  const styles = {}

  return (
    <section className={`${s.block}`} id={block?.blockId} style={styles}>
      {!!bgImage?.data &&
        <GatsbyImage
          loading="eager"
          placeholder="dominantColor"
          image={bgImage.data}
          alt={bgImage.alt}
          className={s.bg} />
      }
      <div className="container position-relative">
        <div className={`row`}>
          <div className='col-12'>
            {!!block.title && <h2 className={`${s.title}`}>{parse(block.title)}</h2>}
          </div>
          <div className='col-12'>
            <div className={`row ${s.itemsWrapper}`}>
              {block.items.map((item, i) => {
                return <div className='col-lg-6 col-xxl-4' key={i}>
                  <Item item={item} />
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Item = ({ item }) => {

  let icon = {
    src: item?.icon?.localFile?.publicURL,
    alt: item?.icon?.altText
  }

  return <div className={s.item}>
    {!!icon?.src && <img src={icon.src} alt={icon?.alt} loading='lazy' />}
    <div className={`${s.itemInfo}`}>
      {!!item?.title && <>
        {!!item?.subIndustryPage?.uri
          ? <Link to={item?.subIndustryPage?.uri} target="_blank" className={s.itemTitle}>{parse(item?.title)}</Link>
          : <div className={s.itemTitle}>{parse(item?.title)}</div>
        }
      </>
      }
      {!!item?.description && <div className={s.itemDescription}>{parse(item?.description)}</div>}
    </div>
  </div>
}

export default IndustryWhoWeHelp;
